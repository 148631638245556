import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  // Requests headers
  InterceptorSkipHeader = 'XPO-Skip-Interceptor';
  // Formats
  DATE_FORMAT_FOR_REQUESTS = 'YYYY-MM-DD';
  DATE_FORMAT_FOR_DISPLAY = 'MM/DD/YYYY';
  SHORT_YES = 'Y';
  SHORT_NO = 'N';
  // Ruleset
  PROGRAM_TYPE_CD = 'programTypeCd';
  TRAFFIC_TYPE_CD = 'trafficTypeCd';
  RULESET_VARIABLE = 'variable';
  RULESET_REGULAR = 'regular';
  RULESET_MATRIX = 'matrix';
  // Status
  ACTIVE = 'Active';
  matches = 'Matches';
  lessThanOrEqual = 'LessThanOrEqual';
  greaterThanOrEqual = 'GreaterThanOrEqual';
}
