import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { RequestRulesetDialogComponent } from './request-ruleset-dialog/request-ruleset-dialog.component';

const dialogs = [
  RequestRulesetDialogComponent,
];

@NgModule({
    declarations: [...dialogs],
    imports: [
        // MATERIAL OR NGX CORE COMPONENTS
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        XpoButtonModule,
        XpoDialogModule,
        FlexLayoutModule,
        MatDialogModule,
    ],
    exports: [...dialogs]
})
export class DialogModule {}
