import { Injectable } from '@angular/core';
import { ConfigManagerProperties } from '../../shared/enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConstantsService } from '../../shared/services/constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationUtilsService {
  constructor(public configManagerService: ConfigManagerService, public constantsService: ConstantsService) {
  }

  buildVersion: string = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  readonly helpLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Accessorial%20Management.aspx';
  readonly releaseNotesLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Accessorial%20Management%20Release%20Notes.aspx';

}
