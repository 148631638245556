import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

export interface LinkCellRendererParams extends ICellRendererParams {
  onClickCallback: (node: RowNode) => void;
  displayValueCallback: (node: RowNode) => string;
}

@Component({
  selector: 'app-link-cell-renderer',
  templateUrl: './link-cell-renderer.component.html',
  styleUrls: ['./link-cell-renderer.component.scss'],
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
  constructor() {}
  params: LinkCellRendererParams;
  displayValue:string;

  agInit(params: LinkCellRendererParams): void {
    this.params = params;
    if (this.params.displayValueCallback instanceof Function) {
      this.displayValue = this.params.displayValueCallback(this.params.node);
    }
  }

  openDetail() {
    if (this.params.onClickCallback instanceof Function) {
      this.params.onClickCallback(this.params.node);
    }
  }

  refresh(params: any): boolean {
    return true;
  }
}
