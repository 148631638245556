<h1 mat-dialog-title>
  Request Ruleset Detail
  <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>
<form [formGroup]="agreementParams" (ngSubmit)="search()">
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="space-around strech">
    <mat-form-field fxFlex="100">
      <mat-label>Agreement ID</mat-label>
      <input matInput formControlName="agreementId" placeholder="Enter Agreement ID" />
      <mat-error *ngIf="agreementParams.controls['agreementId'].hasError('required')">
        Agreement ID is&nbsp;<strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="100">
      <mat-label>Ruleset Seq Number</mat-label>
      <input matInput formControlName="ruleSetSequenceNbr" placeholder="Enter Ruleset Sequence Number" />
      <mat-error *ngIf="agreementParams.controls['ruleSetSequenceNbr'].hasError('required')">
        Ruleset Sequence Number is&nbsp;<strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="100" xpoDatePicker>
      <mat-label>Effective Date</mat-label>
      <input matInput formControlName="effectiveDate" [matDatepicker]="basicDatePickerPreFilled" />
      <mat-datepicker-toggle matSuffix [for]="basicDatePickerPreFilled">
        <mat-icon  >calendar_today</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #basicDatePickerPreFilled></mat-datepicker>
    </mat-form-field>
  </div>
</form>
<div *ngIf="error" class="error">{{ error }}</div>
<div mat-dialog-actions>
  <xpo-button-group>
    <button mat-flat-button xpoButton (click)="search()" [disabled]="agreementParams.invalid" data-test="searchBtn">
      Ruleset view
    </button>
  </xpo-button-group>
  <button mat-stroked-button xpoButton (click)="cancel()">Cancel</button>
</div>
